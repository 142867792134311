import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage'
import { switchMap, first, timestamp } from 'rxjs/operators';
import { SnackService } from './snack.service';
import { DataEvent } from '../data/data-event.model';
import { DataVolunteer } from '../data/data-volunteer.model';
import { DataMessage } from '../data/data-message.model';
import { firestore } from 'firebase';
import { JsonPipe } from '@angular/common';
import { combineLatest, Observable } from 'rxjs';
import { DataPrize } from '../data/data-prize.model';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  public currentUser:firebase.User;

  constructor(
    public afAuth: AngularFireAuth,
    public db: AngularFirestore,
    public afStorage: AngularFireStorage,
  ) {

    afAuth.authState.subscribe(user =>{
      this.currentUser = user;
    });

  }

  publishEvent(event:DataEvent)
  {
    if(event.status !== "draft")
    {
      window.alert("This cannot be done");
      return;
    }

    this.db.collection<DataEvent>('events').doc(event.id).set({status:'open'},{merge:true});

  }

  finalizeEvent(event:DataEvent)
  {
    var eventEndTime = event.info.endDate;
    var now = firestore.Timestamp.now();

    var end = now.seconds < eventEndTime.seconds ? now : eventEndTime;
    
    this.db.collection<DataEvent>('events').doc(event.id).set({status:'closed'},{merge: true})
    .then((snapshot)=>{
      return this.db.collection<DataEvent>('events').doc(event.id).collection<DataVolunteer>('volunteers').get().toPromise() ;
    })    
    .then((query)=>{
      
      var batch = this.db.firestore.batch();

      query.docs.forEach(doc => {
        //this is the volunteer object under event/volunteers
        //also update the volunteerobject under users/events/eventid

        var userEventRef = this.db.collection('users').doc(this.currentUser.uid).collection('events').doc(event.id).ref;

        var totalPoints = (end.seconds - doc.get('checkin').seconds ) / 36 ;
        if(totalPoints < 50) totalPoints = 50; // minimum payout

        batch.set(doc.ref, {checkout: end, goodpoints: totalPoints}, {merge: true});
        batch.set(userEventRef, {checkout: end, goodpoints: totalPoints}, {merge: true});

        batch.set(this.db.collection('users').doc(this.currentUser.uid).ref, {currentGoodPoints: firestore.FieldValue.increment(totalPoints) }, {merge:true})
        batch.set(this.db.collection('users').doc(this.currentUser.uid).ref, {totalGoodPoints: firestore.FieldValue.increment(totalPoints) }, {merge:true})
      })
      
      return batch.commit();

    })
    .then(()=>{
      this.db.collection<DataEvent>('events').doc(event.id).set({closeTime:end},{merge: true})
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  getMailByEventID(id)
  {
    return this.afAuth.authState.pipe(
      switchMap( user =>{
        if(user)
        {
          console.log('loading mail for event: ' + id);

          return this.db.collection<DataMessage>('mail', ref => ref
            .where('event_id','==',id))
            .valueChanges();
        }else
        {
          return [];
        }
      })
    )
  }

  getSponsorInfo()
  {
    return this.afAuth.authState.pipe(
      switchMap( user => {
        if( user )
        {
          console.log('loading sponsor: ' + user.uid);
          
          return this.db.collection<DataEvent>('sponsors')
          .doc(user.uid)
          .valueChanges();
        } else
        {
          return [];
        }
      })
    )
  }

  setSponsorInfo(info)
  {
    return this.db.collection<DataEvent>('sponsors').doc(this.currentUser.uid).set(info, {merge:true});
  }

  getMyEvents(): Observable<DataEvent[]>
  {
    return this.afAuth.authState.pipe(
      switchMap( user => {
        if( user )
        {
          console.log('loading events for user: ' + user.uid);
          
          return this.db.collection<DataEvent>('events'
          ,ref => ref.where('uid', '==', user.uid)
          )
          .valueChanges({ idField: 'id'});
        } else
        {
          return [];
        }
      })
    )
  }

  getMyPrizes()
  {
    return this.afAuth.authState.pipe(
      switchMap( user => {
        if( user )
        {          
          return this.db.collection<DataPrize>('prizes'
          ,ref => ref.where('uid', '==', user.uid)
          )
          .valueChanges({ idField: 'id'});
        } else
        {
          return [];
        }
      })
    )
  }
  
  fetchEvent(id)
  {
    return this.afAuth.authState.pipe(
      switchMap( user => {
        if( user )
        {
          return this.db.collection('events').doc<DataEvent>(id).valueChanges();
        }else
        {
          return [];
        }
      })
    )
  }

  getEventVolunteers(id)
  {
    return this.afAuth.authState.pipe(
      switchMap( user => {
        if( user )
        {
          return this.db.collection('events').doc(id).collection('volunteers').valueChanges({ idField: 'id'});
        }else
        {
          return [];
        }
      })
    )
  }

  async messageVolunteer(volunteer:DataVolunteer, message:string, event:DataEvent, reply:string)
  {

    if(message === undefined || message.length < 1)
    {
      window.alert('message cannot be blank');
      return;
    }
    
    let data = {
      message: message,
      user_from: this.currentUser.uid,
      user_to: volunteer.id,
      sent_at: firestore.Timestamp.now(),
      event_id: event ? event.id : null,
      reply_to: reply,
    } as DataMessage;

    return this.db.collection('mail').add(data)
    .then((docRef)=>{
      console.log('sent successfully!');
    })
    .catch((err)=>{
      window.alert('mail send fail: ' + err);
    });

  }

  async removeVolunteer(volunteer:DataVolunteer, event: DataEvent)
  {
    this.db.collection('log').add({action: "deleted volunteer", auth:this.currentUser.uid, event:event.id, volunteer:volunteer.id, time:firestore.Timestamp.now()});
    return this.db.collection('events').doc(event.id).collection('volunteers').doc(volunteer.id).delete();
  }

  async createEvent(data)
  {
    const user = await this.afAuth.currentUser;

    return this.db.collection('events').add(
      {
        ...data,
        uid: user.uid
      }
    ).then(()=>{
      location.reload();
    })
  }

  uploadImage(eventID, event)
  {
    return this.afStorage.upload('events/' + eventID, event.target.files[0])
    .then((uploadTask)=>{
      return uploadTask.ref.getDownloadURL();
    })
    .then((url)=>{
      return this.db.collection('events').doc(eventID).set({headerImageURL:url}, {merge:true});
    })
    .catch((err)=>
    {
      console.log('whoops: ' + err);
    });

  }
}
