<mat-sidenav-container class="sidenav-container">

    <!-- sidebar -->
    <mat-sidenav #drawer class="sidenav" fixedInViewport
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened] = "false">

      <!-- <mat-toolbar color="accent">Navigation</mat-toolbar> -->

      <mat-nav-list>
        <div *ngIf="!(afAuth.authState | async)">
            <a mat-list-item routerLink="/login" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" (click)="drawer.close()">Login</a>
        </div>

        <div *ngIf="afAuth.authState | async as user">
            <a mat-list-item routerLink="/dashboard" routerLinkActive="active" (click)="drawer.close()">Dashboard</a>
            <a mat-list-item routerLink="/events" routerLinkActive="active" (click)="drawer.close()">Events</a>
            <a mat-list-item routerLink="/prizes" routerLinkActive="active" (click)="drawer.close()">Prizes</a>
        </div>
      </mat-nav-list>

      <div class="sidenav-bottom" *ngIf="afAuth.authState | async">
          <mat-nav-list>
            <a mat-list-item routerLink="/login" (click)="afAuth.signOut()">Logout</a>
          </mat-nav-list>
      </div>

    </mat-sidenav>

    <mat-sidenav-content>
        <!-- top bar -->
        <mat-toolbar id="navBar" color="primary">
            <img class="logo-icon" src="assets/img/logo.png"/>
            <span class="logo" routerLink="/">&nbsp;GoodPoints</span>

            <span class="fill-space"></span>

            <div *ngIf="!isHandset$; else mobileNav">
                <div *ngIf="!(afAuth.authState | async)">
                    <a mat-button routerLink="/login" routerLinkActive="active">Login</a>
                </div>
    
                <div *ngIf="afAuth.authState | async as user">
                    <a mat-button routerLink="/dashboard" routerLinkActive="active">Dashboard</a>
                    <a mat-button routerLink="/events" routerLinkActive="active">Events</a>
                </div>
            </div>
            

            <ng-template #mobileNav>
                <button
                    type = "button"
                    aria-label="Toggle sidenav"
                    mat-icon-button
                    (click)="drawer.toggle()"
                    >

                    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
                </button>
            </ng-template>
            

        </mat-toolbar>

        <ng-content></ng-content>

        <app-footer></app-footer>

    </mat-sidenav-content>
</mat-sidenav-container>