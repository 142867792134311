import { NONE_TYPE } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { DataPrize } from '../data/data-prize.model';
import { DataSponsor } from '../data/data-sponsor.model';
import { DatabaseService } from '../services/database.service';
import { SharedModule } from '../shared/shared.module';
import { PrizeDisplayComponent } from '../prize-portal/prize-display/prize-display.component'

@Component({
  selector: 'app-prize-portal',
  templateUrl: './prize-portal.component.html',
  styleUrls: ['./prize-portal.component.scss']
})
export class PrizePortalComponent implements OnInit {

  isDirty:boolean;

  sponsor:DataSponsor = {} as DataSponsor;
  lastSponsor:DataSponsor;

  prizes:DataPrize[];

  sponsorName:string;
  sponsorAbout:string;

  default_square = 'https://firebasestorage.googleapis.com/v0/b/goodpoints-dev.appspot.com/o/static%2F512-512.png?alt=media&token=b38c7993-b6f1-47e1-8129-8148199cf1d6';
  default_rect = 'https://firebasestorage.googleapis.com/v0/b/goodpoints-dev.appspot.com/o/static%2F1024-512.png?alt=media&token=5363fe9f-a6e9-465e-aefc-4ec5ae6ea4c7';

  logo_square;
  logo_rect;

  constructor(
    public db:DatabaseService ,
  ) { }

  setDirty(): void
  {
    this.isDirty = true;
  }
  
  uploadSquare(browserEvent){
    if(!browserEvent.target.files || browserEvent.target.files.length == 0) return;
    let file = browserEvent.target.files[0]
    let img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () =>{
      if(img.width != img.height || img.width > 1024)
      {
        window.alert("width must == height and not exceed 1024x1024 in size");
        return;
      }

      this.sponsor.logo_square = "";
      this.db.afStorage.upload('sponsors/sqr_' + this.db.currentUser.uid, browserEvent.target.files[0])
      .then((upload)=>{
        return upload.ref.getDownloadURL();
      })
      .then((url)=>{
        return this.db.db.collection('sponsors').doc(this.db.currentUser.uid).set({logo_square:url}, {merge:true});
      })
    }
    //this.db.uploadImage(this.event.id, browserEvent);
  }

  uploadRect(browserEvent){
    if(!browserEvent.target.files || browserEvent.target.files.length == 0) return;
    let file = browserEvent.target.files[0]
    let img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () =>{
      if(img.width != img.height * 2 || img.width > 1024)
      {
        window.alert("width must be 2x height and not exceed 512x1024 in size");
        return;
      }

      this.sponsor.logo_twobyone = "";
      this.db.afStorage.upload('sponsors/rct_' + this.db.currentUser.uid, file)
      .then((upload)=>{
        return upload.ref.getDownloadURL();
      })
      .then((url)=>{
        return this.db.db.collection('sponsors').doc(this.db.currentUser.uid).set({logo_twobyone:url}, {merge:true});
      })
    }
    //this.db.uploadImage(this.event.id, browserEvent);
  }

  ngOnInit(): void {
    this.db.getSponsorInfo().subscribe( s => 
      {
        if(s === undefined)
        {
          console.log("null");
          this.sponsor = {} as DataSponsor;
        }else
        {
          console.log("found");
          this.sponsor = s;
        }

        this.sponsorName = this.sponsor.displayName || '';
        this.sponsorAbout = this.sponsor.about || '';
        this.isDirty = false;
      });

    this.db.getMyPrizes()
      .subscribe(p =>{
        this.prizes = p;
        
        console.log("found " + this.prizes.length + " prizes");
        console.log(this.prizes);
      });
  }
  create(): void{
    let prize = {
      imageurl: "",
      title: "Title",
      description: "Description",
      tier: "1000",
      uid: this.db.currentUser.uid
    } as DataPrize;
    this.db.db.collection("prizes").add(prize);
  }

  saveChanges()
  {
    
    this.sponsor.displayName = this.sponsorName;
    this.sponsor.about = this.sponsorAbout;

    this.isDirty = false;
    this.db.setSponsorInfo(this.sponsor);
    
  }

  cancelChanges()
  {

  }
    

  openProfileEditor(){
    document.getElementById('profileEditor').classList.remove('hidden');
    document.getElementById('profileData').classList.add('hidden');
  }
  closeProfileEditor(){
    document.getElementById('profileEditor').classList.add('hidden');
    document.getElementById('profileData').classList.remove('hidden');
    this.saveChanges();
  }
}
