<div style="height: 100%;">
  
  <h1>TERMS & CONDITIONS</h1>

  <h3>TERMS</h3>
  By accessing this web site, you are agreeing to be bound by these web site Terms and Conditions, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this web site are protected by applicable copyright and trademark law.
  
  <h3>DISCLAIMER</h3>
  The materials on GoodPoints, LLC.’s web site are provided “as is”. GoodPoints, LLC. makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, GoodPoints, LLC. does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its Internet web site or otherwise relating to such materials or on any sites linked to this site.
  
  <h3>LIMITATIONS</h3>
  In no event shall GoodPoints, LLC. or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption), arising out of the use or inability to use the materials on GoodPoints, LLC. internet site, even if GoodPoints, LLC. or a GoodPoints, LLC. authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.
  
  <h3>LEGAL NOTICE</h3>
  All notices from GoodPoints, LLC. to You may be posted on our Web site and will be deemed delivered within thirty (30) days after posting. Notices from You to GoodPoints, LLC. shall be made either by e-mail, sent to the address we provide on our Web site, or first-class mail to our address at: 
  GoodPoints, LLC.
  3102 E. Turney Ave.
  Phoenix, AZ 85018
  Delivery shall be deemed to have been made by You to GoodPoints, LLC. five (5) days after the date sent.
  
  <h3>COPYRIGHT</h3>
  All content appearing on this Web site is the property of: 
  GoodPoints, LLC.
  3102 E. Turkey Ave.
  Phoenix, AZ 85018
  Copyright © GoodPoints, LLC. All rights reserved. As a user, you are authorized only to view, copy, print, and distribute documents on this Web site so long as (1) the document is used for informational purposes only, and (2) any copy of the document (or portion thereof) includes the following copyright notice: Copyright © GoodPoints, LLC. All rights reserved.
  
  <h3>TRADEMARKS</h3>
  All brand, product, service, and process names appearing on this Web site are trademarks of their respective holders. Reference to or use of a product, service, or process does not imply recommendation, approval, affiliation, or sponsorship of that product, service, or process by GoodPoints, LLC. Nothing contained herein shall be construed as conferring by implication, estoppel, or otherwise any license or right under any patent, copyright, trademark, or other intellectual property right of GoodPoints, LLC. or any third party, except as expressly granted herein.
  
  <h3>USE OF SITE</h3>
  This site may contain other proprietary notices and copyright information, the terms of which must be observed and followed. Information on this site may contain technical inaccuracies or typographical errors. Information, including product pricing and availability, may be changed or updated without notice. GoodPoints, LLC. and its subsidiaries reserve the right to refuse service, terminate accounts, and/or cancel orders in its discretion, including, without limitation, if GoodPoints, LLC. believes that customer conduct violates applicable law or is harmful to the interests of GoodPoints, LLC. and its subsidiaries.
  
  <h3>WARRANTIES</h3>
  The Content included in this Web site has been compiled from a variety of sources and is subject to change without notice as are any products, programs, offerings, or technical information described in this Web site. GoodPoints, LLC. makes no representation or warranty whatsoever regarding the completeness, quality, or adequacy of the Web site or Content, or the suitability, functionality, or operation of this Web site or its Content. By using this Web site, you assume the risk that the Content on this Web site may be inaccurate, incomplete, offensive, or may not meet your needs and requirements. GOODPOINTS, LLC. SPECIFICALLY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NONINFRINGEMENT WITH RESPECT TO THESE WEB PAGES AND CONTENT. IN NO EVENT WILL GOODPOINTS, LLC. BE LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL, OR CONSEQUENTIAL DAMAGES EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. 
  -- OR Depending on State -- 
  The information and content on this server is provided "as is" with no warranty of any kind, either express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement. Any warranty that is provided in connection with any of the products and services described on this Web site is provided by the advertiser or manufacturer only, and not by GoodPoints, LLC.
  The references and descriptions of products or services within the Web site materials are provided "as is" without any warranty of any kind, either express or implied. GoodPoints, LLC. is not liable for any damages, including any consequential damages, of any kind that may result to the user from the use of the materials on this Web site or of any of the products or services described hereon. 
  The descriptions of, and references to, products, services and companies on this Web site are the sole responsibility of the companies providing the information ("advertisers"), and not GoodPoints, LLC. 
  The inclusion of material on this server does not imply any endorsement by GoodPoints, LLC., which makes no warranty of any kind with respect to the subject matter of the server materials advertised. 
  A possibility exists that the server materials could include inaccuracies or errors. Additionally, a possibility exists that unauthorized additions, deletions, and alterations could be made by third parties to the server materials. Although GoodPoints, LLC. tries to ensure the integrity and the accurateness of the server materials, it makes no guarantees about their correctness or accuracy. Before relying on any representation made in any of the server materials, check with the advertiser of the product or service to ensure that the information you are relying upon is correct.
  
  <h3>MISCELLANEOUS</h3>
  <p>
    VOID WHERE PROHIBITED: Although the information on this Web site is accessible worldwide, not all products or services discussed in this Web site are available to all persons or in all geographic locations or jurisdictions. GoodPoints, LLC. and the advertisers each reserve the right to limit the provision of their products or services to any person, geographic area, or jurisdiction they so desire and to limit the quantities of any products or services that they provide. Any offer for any product or service made in the materials on this Web site is void where prohibited. 
    GOVERNING LAW: In the event of litigation both parties agree that the Law of the State of business registration of GoodPoints, LLC. shall apply and both parties shall consent to the jurisdiction of said State's courts, or in the event of diversity of citizenship, the United States District Court for the (District). Both parties expressly waive a trial by jury. 
    MISCELLANEOUS: The Terms and Conditions constitute the entire agreement between you and GoodPoints, LLC. with respect to this Web site. The Terms and Conditions supersede all prior or contemporaneous communications and proposals, whether electronic, oral or written between you and GoodPoints, LLC. with respect to this Web site. No modification of the Terms and Conditions shall be effective unless it is authorized by GoodPoints, LLC. If any provision of the Terms and Conditions is found to be contrary to law, then such provision(s) shall be constructed in a manner to closely reflect, as much as possible, the intentions of the parties, with the other provisions remaining in full force and effect.  
  </p>
  <br><br><br><br><br><br><br>
</div>