import { Component, Input, OnInit } from '@angular/core';
import { DataPrize } from 'src/app/data/data-prize.model';
import { DatabaseService } from 'src/app/services/database.service';

@Component({
  selector: 'app-prize-display',
  templateUrl: './prize-display.component.html',
  styleUrls: ['./prize-display.component.scss']
})
export class PrizeDisplayComponent implements OnInit {
  @Input() prize:DataPrize;

  constructor(public db:DatabaseService) { }

  defaultImage = 'https://firebasestorage.googleapis.com/v0/b/goodpoints-dev.appspot.com/o/static%2F1024-512.png?alt=media&token=5363fe9f-a6e9-465e-aefc-4ec5ae6ea4c7';
  isDirty:boolean = false;

  ngOnInit(): void {
  }

  setDirty(): void
  {
    this.isDirty = true;
  }

  save()
  {
    this.db.db.collection("prizes").doc(this.prize.id).set(this.prize);
  }
  remove()
  {
    if(window.confirm("Confirm Delete?"))
      this.db.db.collection("prizes").doc(this.prize.id).delete();
  }

  uploadIcon(browserEvent){
    if(!browserEvent.target.files || browserEvent.target.files.length == 0) return;
    let file = browserEvent.target.files[0]
    let img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () =>{
      if(img.width != img.height)
      {
        window.alert("width must equal to height");
        return;
      }

      if(img.width > 1024)
      {
        window.alert("dimensions cannot exceed 1024");
        return;
      }

      this.prize.imageurl = "";
      this.db.afStorage.upload('prizes/rct_' + this.prize.id, file)
      .then((upload)=>{
        return upload.ref.getDownloadURL();
      })
      .then((url)=>{
        return this.db.db.collection("prizes").doc(this.prize.id).set({imageurl:url}, {merge:true});
      })
    }
    //this.db.uploadImage(this.event.id, browserEvent);
  }

}
