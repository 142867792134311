
<div>
  <h1>PRIVACY POLICY</h1>

  GoodPoints, LLC. (“Our”, “Us” or “We”) understands and respects your concerns about privacy. This notice describes GoodPoints, LLC. Privacy Policy as it pertains to the website at www.MyGoodPoints.com (the “Website”) and any mobile applications of any kind distributed by GoodPoints, LLC. (the “Applications,” and together with the Website, the “GoodPoints, LLC. Products”). We may from time-to-time revise this Privacy Policy, and the date of last revision will be available at the bottom of this Privacy Policy, so please make sure that you check back periodically. By utilizing and/or downloading the GoodPoints, LLC. Products, you are acknowledging and accepting this Privacy Policy. Your continued use of the GoodPoints, LLC. Products after changes have been posted to the Privacy Policy will constitute your acceptance of such changes.
  
  <h3>OUR POLICY TOWARDS CHILDREN</h3>
  GoodPoints, LLC. is committed to complying with all applicable laws and regulations regarding the collection, storage and use of personally identifiable information concerning children under the age of 13, including the Children’s Online Privacy Protection Act in the United States and applicable local legislation in other territories.
  If you are a parent or guardian that has become aware that your child has provided us with personal information without your consent, you should contact us immediately by way of the contact details provided in the “Contacts” section. We do not knowingly collect personally identifiable information from children under the age of 13. If we become aware that a child under the age of 13 has provided us with personally identifiable information, we will delete such information from our system.
  <h3>WHAT INFORMATION WE COLLECT</h3>
  GoodPoints, LLC. may ask for and collect from you personally identifiable information at certain points throughout the GoodPoints, LLC. Products. Depending on the information and/or services you request, you may be asked to provide your name, email address and other personal information. Once you provide your personal information, you are not anonymous to GoodPoints, LLC.
  In addition to the information you knowingly provide, GoodPoints, LLC. collects information such as the domain names and IP addresses of its visitors, as well as information about the device you use to access the GoodPoints, LLC. Products, including but not limited to unique device identifiers, browser type, browser language, your system and application software, and peripherals. GoodPoints, LLC. may also track, archive, use, disclose and transfer information regarding your use of the GoodPoints, LLC. Products as provided herein, including information about level of usage and particular features used. This data is used to more efficiently operate GoodPoints, LLC. business, promote GoodPoints, LLC. products and services and administer the GoodPoints, LLC. Products.
  GoodPoints, LLC. may combine information it collects from you with information it obtains about you from third parties and affiliates.
  <h3>HOW GOODPOINTS, LLC. USES YOUR INFORMATION</h3>
  GoodPoints, LLC. may use your information:
  – To provide you with personalized content.
  – To process and respond to inquiries.
  – For the purposes for which you provided the information.
  – To improve the content, interactivity, usability, and navigability of the GoodPoints, LLC. Products.
  – To alert you to new GoodPoints, LLC. Products including new features, special events, products and services, and to deliver newsletters from which you may opt out.
  – To enforce GoodPoints, LLC. Terms of Service.
  In the future, GoodPoints, LLC. may sell some or all of our assets. In such transactions, customer information generally is one of the transferred business assets. In the event of a merger or sale of our assets including our database, customer information will be transferred. We will require buyers to honor this Privacy Policy or notify you before making any material changes to the practices outlined in this Policy.
  <h3>INFORMATION SHARING WITH UNAFFILIATED THIRD PARTIES</h3>
  GoodPoints, LLC. may disclose your personally identifiable information to another third-party entity for any reason, including:
  – For purposes of outsourcing one or more of the functions described in the previous section;
  – To confirm or update information provided by you;
  – To inform you of important information; and/or
  – as a part of a sale of assets as described in the previous section.
  We may also share your information in response to a subpoena, legal order or official request, when we believe you have acted in violation of the GoodPoints, LLC. Terms of Service, or when we believe that doing so may protect your safety or the safety of others.
  <h3>INFORMATION SHARING WITH AFFILIATED COMPANIES</h3>
  We may share your personally identifiable information with other companies in the GoodPoints, LLC. family, co-branding partners and network partners, and will require any such recipient to comply with the provisions of this Privacy Policy.
  SHARING AND USE OF DE-IDENTIFIED INFORMATION
  Information that is de-identified (stripped of any information that could be used to identify you) may be used by GoodPoints, LLC. for any reason and shared freely with affiliates, partners and other third parties. This information is usually aggregated (combined with information from many other users), and may include information such as traffic patterns, trends in connection with various types of inquiries, and other information.
  <h3>HOW WE COLLECT INFORMATION</h3>
  In addition to the methods described above, we may also collect information using:
  – Cookies, locally stored Flash objects (sometimes referred to as “Flash cookies”);
  – Web beacons or similar technologies; and
  – Analytic software embedded in the GoodPoints, LLC. Products.
  The above methods permit us to collect various types of information, including, but not limited to, the pages you visit, which of our email messages you read, your specific geolocation (with your permission) and other information.
  <h3>OPTING OUT OF GEOLOCATION</h3>
  If you have previously allowed us to access your geolocation data, you can stop making geolocation available to us by visiting your mobile device’s settings.
  <h3>HOW WE PROTECT INFORMATION</h3>
  Although GoodPoints, LLC. strives to protect your information by implementing reasonable security control measures and safeguards, we are unable to guarantee or warrant the security of any information transmitted to us through the GoodPoints, LLC. Products or that we store on our systems or that is stored on our third-party contractors’ systems.
  <h3>TARGETED ADVERTISING</h3>
  We may use third-party advertising companies to serve ads when you visit and/or use the GoodPoints, LLC. Products. Some of these companies may use information (not including your name, address, email address or telephone number) about your visits and/or use of the GoodPoints, LLC. Products and other websites in order to provide advertisements about goods and services that may be of interest to you. We may also allow advertisers and other third parties to place or recognize a unique cookie or similar technology on your browser in order to collect non-personally identifiable information about your visits and/or use of the GoodPoints, LLC. Products. To learn more about this practice, its benefits, or about your choice to opt-out of this practice for the Website, see http://www.networkadvertising.org/optout_nonppii.asp.
  <h3>LINKS</h3>
  The GoodPoints, LLC. Products may contain links to third-party websites. GoodPoints, LLC. is not responsible for the privacy practices or the content of such websites.
  <h3>CALIFORNIA PRIVACY RIGHTS</h3>
  As of 1st January 2005, California Civil Code Section 1798.83 (the “Code”) permits users who are California residents to request certain information regarding the disclosure of personal information to third parties which is used for their direct marketing purposes. To make such a request, to which GoodPoints, LLC. shall respond to the extent required by the Code, contact us at support@mygoodpoints.com, specifying that you seek your “California Customer Choice Notice.” Please allow thirty (30) days for a response.
  CHANGES TO THIS PRIVACY POLICY
  This Privacy Policy may be updated from time to time and without prior notice to you to reflect changes in our personal information practices. We will post the revised version with an updated revision date, at http://www.MyGoodPoints.com.
  Contacts: If you have any questions, concerns, or suggestions regarding this privacy policy, please contact us at support@mygoodpoints.com.
  Copyright © GoodPoints, LLC. All rights reserved.
  <br><br><br><br><br><br><br>
</div>