import { Component, OnInit } from '@angular/core';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  from_name: string;
  reply_to: string;
  phone: string;
  subject: string;
  message: string;

  constructor() {}

  ngOnInit() {}

  submitForm() {
    emailjs
      .sendForm(
        'mygoodpoints_gmailer',
        'contact-us-form',
        '#contact-form',
        'user_OXvgmf3xrKoux8ATDmnwo'
      )
      .then(
        function (response) {
          console.log('Email Delivered!', response.status, response.text);
          window.alert("Sent!");
          location.reload();
        },
        function (error) {
          console.log('FAILED...', error);
        }
      );
  }
}
