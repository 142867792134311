import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './user/auth.guard';

import { LandingComponent } from './landing/landing.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { LearnMoreComponent } from './learn-more/learn-more.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { PrizePortalComponent } from './prize-portal/prize-portal.component';
import { LegalComponent } from './legal/legal.component';
import { PrivacyComponent } from './privacy/privacy.component';

const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'dashboard',  loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'login', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
  { path: 'events', loadChildren: () => import('./events/events.module').then(m => m.EventsModule) },
  { path: 'prizes', component: PrizePortalComponent},
  { path: 'contact', component: ContactUsComponent },
  { path: 'learn', component: LearnMoreComponent },
  { path: 'about', component: AboutUsComponent },
  { path: 'legal', component: LegalComponent },
  { path: 'privacy', component: PrivacyComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
