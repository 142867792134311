<mat-card class="prizeCard">
    <mat-card-content>

        <div class="prizeHeader">
            <mat-form-field class="prizeTitle">
                <mat-label>Title</mat-label>
                <input matInput type="text" [(ngModel)]="prize.title" (change)="setDirty()">
            </mat-form-field>

            <span class="fill-space"></span>

            <mat-form-field class="prizeTier">
                <mat-label>Tier</mat-label>
                <mat-select matInput [(ngModel)]="prize.tier" (change)="setDirty()">
                    <mat-option value="1000">1000</mat-option>
                    <mat-option value="2000">2000</mat-option>
                    <mat-option value="4000">4000</mat-option>
                </mat-select>
                <!-- <input matInput type="text" [(ngModel)]="prize.tier" (change)="setDirty()"> -->
            </mat-form-field>
        </div>
        <div class="prizeDetails">
            <div class="prizeImgSect">
                <img class="prizeImg" src="{{prize.imageurl || defaultImage}}" alt="Picture of prize for '{{ prize.title }}''" />
                <mat-form-field class="prizeImgForm">
                    <mat-label>ImageURL</mat-label>
                    <input matInput type="text" [(ngModel)]="prize.imageurl" (change)="setDirty()">
                </mat-form-field>
                <input name="file" id="file" type="file" (change)="uploadIcon($event)" accept=".png,.jpg" />
            </div>
            <div class="prizeBody">
                <mat-form-field class="prizeDesc">
                    <mat-label>Description</mat-label>
                    <textarea matInput type="text" [(ngModel)]="prize.description" (change)="setDirty()"></textarea>
                </mat-form-field>
                <div class="prizeBtns">
                    <button mat-raised-button (click)="save()" [disabled]="!isDirty">Save <mat-icon>save</mat-icon></button>
                    <button mat-raised-button (click)="remove()">Remove <mat-icon>delete</mat-icon></button>
                </div>
            </div>
        </div>
        
    </mat-card-content>
</mat-card>
