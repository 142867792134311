import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatBadgeModule } from '@angular/material/badge';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import {ScrollingModule} from '@angular/cdk/scrolling';

import { LayoutModule } from '@angular/cdk/layout';

import { ShellComponent } from './shell/shell.component';
import { FooterComponent } from './footer/footer.component';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { DataSponsor } from '../data/data-sponsor.model';
import { DataPrize } from '../data/data-prize.model';

const components = [ShellComponent, FooterComponent];

const modules = [
  CommonModule,
  MatButtonModule,
  MatToolbarModule,
  MatIconModule,
  LayoutModule,
  MatSidenavModule,
  MatListModule,
  MatMenuModule,
  MatIconModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  RouterModule,
  MatBadgeModule,
  MatGridListModule,
  MatStepperModule,
  FormsModule,
  ReactiveFormsModule,
  MatDatepickerModule,
  MatDividerModule,
  MatNativeDateModule,
  MatRippleModule,
  MatCheckboxModule,
  MatSelectModule,
  MatExpansionModule,
  MatTooltipModule,
  NgbModule,
  ScrollingModule
];

@NgModule({
  declarations: [...components],
  imports: [
    ...modules,
  ],
  exports: [
    ...components,
    ...modules
  ]
})
export class SharedModule {}