<h3>
  About Us
</h3>
<p>
GoodPoints is a platform to unify volunteers, nonprofits, and merchants in a common purpose: to multiply kindness. Volunteers provide the energy to help Nonprofits achieve their mission. Volunteers receive GoodPoints they can use for special consideration with merchants.  And merchants get the patronage of volunteers and the opportunity to be change agents in their communities.
</p>
<p>
GoodPoints was born from the personal experience of its founder, Chris Toward, who ran one of New York’s largest disaster recovery programs after 9/11, managing thousands of volunteers at Ground Zero and throughout New York in support of nonprofit partners. He also managed relationships with dozens of corporations and government agencies, experiencing the enormous capacity they have to change communities when they work together. GoodPoints is an extension of that work at a global scale.
</p>
<p>
Overall, Chris has more than 30 years of experience that encompasses nonprofit, government, corporate, and international work that he brings to bear for the growth of GoodPoints and its mission.
</p>