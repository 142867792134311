<section id="landingPage">
  <!--div id="landingHero"
    [ngStyle]="{
      'background-image': 'url(./../../../assets/img/hero1.jpg)'
    }"
  ></div-->

  <ngb-carousel *ngIf="images">
    <ng-template ngbSlide *ngFor="let imageSrc of images">
      <div class="heroImg">
        <img src="{{ imageSrc }}" />
      </div>
    </ng-template>
  </ngb-carousel>

  <h1 class="headerText">Welcome to GoodPoints!</h1>

  <div id="contentSection">
    <h2>For Nonprofits</h2>
    <div class="infoSection">
      <img src="./../../assets//img/landing3.jpg" />
      <p class="detailText">
        Nonprofit organizations perform critical functions in our communities,
        and they rely on volunteers to operate. GoodPoints helps ease the constant
        challenges of recruiting, engaging, retaining, and thanking volunteers.
      </p>
    </div>

    <h2>For Volunteers</h2>
    <div class="infoSection">
      <img src="./../../assets//img/landing1.jpg" />
      <p class="detailText">
        65 million Americans--and 970 people worldwide—volunteer every year.
        Our mission is to grow that number ever-larger by matching them with 
        merchants who want to thank them for their contributions to the community
        with special discounts. Volunteers have earned a special social status and
        GoodPoints provides it when they make everyday purchases.
      </p>
    </div>

    <h2>For Merchants</h2>
    <div class="infoSection">
      <img src="./../../assets//img/landing2.jpg" />
      <p class="detailText">
        GoodPoints delivers customers to merchants in a unique way. It’s not 
        just another discount platform, it’s a way to build a deeper affinity 
        with your customers based on causes they invest their time and passion into. 
        Philanthropy is often reserved for the Fortune 500 and billionaires. 
        Our platform let's even the smallest merchant participate in a meaningful way.
      </p>
    </div>
  </div>
</section>
