// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAGVJYZjEWv2YQh7Vn048uZ0zIJZPRLmpk",
    authDomain: "goodpoints-dev.firebaseapp.com",
    databaseURL: "https://goodpoints-dev.firebaseio.com",
    projectId: "goodpoints-dev",
    storageBucket: "goodpoints-dev.appspot.com",
    messagingSenderId: "732547700102",
    appId: "1:732547700102:web:7c42f76bc59c03cdb64807"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
