<footer>
  <ul id="footerNav">
    <li>
      <a routerLink="/about"><strong><u>About Us</u></strong></a>
    </li>
    <li>
      <a routerLink="/contact"><strong><u>Contact Us</u></strong></a>
    </li>
  </ul>
  <br>
  <ul id="footerNav">
    <li>
      <a routerLink="/legal"><strong><u>Terms & Conditions</u></strong></a>
    </li>
    <li>
      <a routerLink="/privacy"><strong><u>Privacy Policy</u></strong></a>
    </li>
  </ul>
  <div id="credits">
    <p>© GoodPoints, LLC. All Rights Reserved</p>
  </div>
</footer>