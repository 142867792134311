<div id="prizePortal">
    <div id="profileSect">
        <div id="profileData">
            <img id="profileHeader" src="{{ sponsor.logo_twobyone || default_rect }}" />
        
            <div id="profileInfo">
                <div>
                    <img id="profileImg" src="{{ sponsor.logo_square || default_square }}" />
                    <h2 id="profileName">{{ sponsorName || "Display Name"}}</h2>
                </div>
                
                <div id="profileAbout">
                    <p>{{ sponsorAbout || "About You"}} </p>
                </div>

                <button
                    id="openProfileEditorBtn"
                    type = "button"
                    mat-icon-button
                    (click)="openProfileEditor()"
                >
                    EDIT PROFILE
                </button>
            </div>
        </div>
        

        <mat-card id="profileEditor" class="hidden">
            <mat-card-title>
                Edit Profile
            </mat-card-title>
            <mat-card-content>
    
                <div id="headerImgSect">
                    <mat-card-header>
                        Header Image <span>(2:1 Ratio)</span>
                    </mat-card-header>
                    <img id="profileHeader" src="{{sponsor.logo_twobyone}}" *ngIf="sponsor && sponsor.logo_twobyone">     
                    <div>
                        <input name="file" id="file" type="file" (change)="uploadRect($event)" accept=".png,.jpg" />
                    </div>
                </div>
                <div id="profilePicSect">
                    <mat-card-header>
                        Logo <span>(1:1 Ratio)</span>
                    </mat-card-header>
                
                    <div>
                        <img id="profileImg" src="{{ sponsor.logo_square || default_square }}" />
                    </div>
                
                    <div>
                        <input name="file" id="file" type="file" (change)="uploadSquare($event)" accept=".png,.jpg" />
                    </div>
                </div>
                
                
                <mat-form-field id="displayNameSect">
                    <mat-label>Display Name</mat-label>
                    <input matInput type="text" [(ngModel)]="sponsorName" (change)="setDirty()">
                </mat-form-field>
    
                <mat-form-field id="aboutMeSect">
                    <mat-label>About</mat-label>
                    <textarea matInput type="text" [(ngModel)]="sponsorAbout" (change)="setDirty()"></textarea>
                </mat-form-field>
                
                
            </mat-card-content>
            <button
                id="closeProfileEditorBtn"
                type = "button"
                mat-icon-button
                (click)="closeProfileEditor()"
            >
                SAVE PROFILE
            </button>
        </mat-card>
        <br><br><br><br><br><br><br>
    </div>
    
    <div id="prizeBoard">
        <button mat-raised-button (click)="create()">Create New Prize</button>
        
        <app-prize-display
            *ngFor="let prize of prizes"
            [prize]="prize"
        >
        </app-prize-display>
    </div>
</div>

<!-- <div class="row">
    <div class="col">
        <mat-card class="mini">
            <mat-card-header>
                <mat-card-title>
                    About You
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
        
                <mat-form-field class="full-width">
                    <mat-label>Display Name</mat-label>
                    <input matInput type="text" [(ngModel)]="sponsorName" (change)="setDirty()">
                </mat-form-field>
        
                <p>
        
                </p>
        
                <mat-form-field class="full-width">
        
                    <mat-label>About</mat-label>
                    <textarea matInput type="text" [(ngModel)]="sponsorAbout" (change)="setDirty()"></textarea>
                        
                </mat-form-field>
        
                <button mat-raised-button *ngIf="isDirty"
                    (click)="saveChanges()"> save</button>
            </mat-card-content>
        </mat-card>
        
        <mat-card class="mini">
            <mat-card-header>
                <mat-card-title>
                    Icon (1:1)
                </mat-card-title>
            </mat-card-header>
        
            <div>
                <img mat-card-image src="{{sponsor.logo_square}}" *ngIf="sponsor && sponsor.logo_square">
                <div>
                    <input name="file" id="file" type="file" (change)="uploadSquare($event)" accept=".png,.jpg" />
                </div>
            </div>
        
        </mat-card>
        
        <mat-card class="mini">
            <mat-card-header>
                <mat-card-title>
                    Header Image (2:1)
                </mat-card-title>
            </mat-card-header>
        
            <div>
                <img mat-card-image src="{{sponsor.logo_twobyone}}" *ngIf="sponsor && sponsor.logo_twobyone">     
                <div>
                    <input name="file" id="file" type="file" (change)="uploadRect($event)" accept=".png,.jpg" />
                </div>
            </div>
        </mat-card>
    </div>
</div> -->

