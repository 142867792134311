<section id="contact-us">
  <h1>Contact Us</h1>

  <form id="contact-form" #contactForm="ngForm" (ngSubmit)="submitForm()">
    <div class="form-row">
      <div class="input-data">
        <input
          type="text"
          id="from_name"
          name="from_name"
          placeholder="Full Name"
          [(ngModel)]="from_name"
          required
          minLength="2"
          #from_nameInput="ngModel"
        />
        <label for="from_name">Full Name</label>
      </div>
    </div>

    <div class="form-row">
      <div class="input-data">
        <input
          type="email"
          id="reply_to"
          name="reply_to"
          placeholder="Email"
          [(ngModel)]="reply_to"
          required
          email
          #emailInput="ngModel"
        />
        <label for="reply_to">Email</label>
      </div>
      <div class="input-data">
        <input
          type="tel"
          id="phone"
          name="phone"
          pattern="[0-9]{10}"
          placeholder="Phone Number"
          [(ngModel)]="phone"
          required
          #phoneInput="ngModel"
        />
        <label for="phone">Phone</label>
      </div>
    </div>

    <div class="form-row">
      <div class="input-data">
        <input
          type="text"
          id="subject"
          name="subject"
          placeholder="Subject"
          [(ngModel)]="subject"
          required
          #subjectInput="ngModel"
        />
        <label for="subject">Subject</label>
      </div>
    </div>

    <div class="form-row">
      <div class="input-data textarea">
        <textarea
          name="message"
          id="message"
          cols="30"
          rows="10"
          placeholder="Message"
          [(ngModel)]="message"
          required
          #messageInput="ngModel"
        ></textarea>
        <label for="message">Message</label>
      </div>
    </div>

    <div class="form-row submit-btn">
      <div class="input-data">
        <button id="submit-btn" mat-raised-button type="submit">Submit</button>
      </div>
    </div>
  </form>
</section>
